import * as React from 'react';
import {MDXProvider} from '@mdx-js/react';
import Layout from './layout';
import {ContainerLg} from './components';

const MarkdownTemplate = ({children}: {children: React.ReactNode}) => {
  return (
    <Layout
      title="Call for papers - Armada JS 2024"
      description="We are excited to welcome the JavaScript community to submit talks for Armada JS 2024 that will take place on the 5th and 6th of December.
    The deadline to submit a proposal is the 1st of October 2024, but the sooner you submit the better."
      imageUrl="https://armada-js.com/images/cfp-link-preview.png?43629843784"
    >
      <ContainerLg>
        <MDXProvider components={{}}>{children}</MDXProvider>
      </ContainerLg>
    </Layout>
  );
};

export default MarkdownTemplate;
