import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/markoletic/Documents/Code/armada-js-website/src/components/markdown-template.tsx";
import { ExternalLinkButton } from '../components/components';
import GalleryDinnerSection from '../components/sections/gallery-dinner-section';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`CALL FOR PAPERS IS CLOSED FOR 2024`}</h1>
    <p>{`Hello there!
We're excited to welcome the JavaScript community to submit talks for Armada JS 2024 that will take place on the 5th and 6th of December.
The deadline to submit a proposal is the 1st of October 2024, but the sooner you submit the better.`}</p>
    <p>{`We don't have a specific theme for Armada JS. Instead, we're aiming to select talks that are interesting to as many JavaScript developers as possible. However, we like the talks to touch on one of these three areas (% shows how many from each topic we like):`}</p>
    <ul>
      <li parentName="ul">{`~30%: Future of JavaScript`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`~50%: Things people can bring right back to work`}</li>
      <li parentName="ul">{`~20%: Fun, crazy, or fascinating code projects`}</li>
    </ul>
    <h2>{`Topics`}</h2>
    <p>{`Our audience skill level is intermediate to advanced.
To get more ideas on the possible topics take a look at this list:`}</p>
    <ul>
      <li parentName="ul">{`Architecture of modern JS applications;`}</li>
      <li parentName="ul">{`Client Side: best practices, performance, memory management;`}</li>
      <li parentName="ul">{`Node.js: best practices, performance, memory management;`}</li>
      <li parentName="ul">{`JS engines (V8, SpiderMonkey, ChakraCore, etc.);`}</li>
      <li parentName="ul">{`Optimizing JS applications;`}</li>
      <li parentName="ul">{`Customers-server synchronization, client-side data storage;`}</li>
      <li parentName="ul">{`Working with graphics and VR (WebGL, D3.js, etc.);`}</li>
      <li parentName="ul">{`UI development;`}</li>
      <li parentName="ul">{`Accessibility with JS`}</li>
      <li parentName="ul">{`Mobile apps (ReactNative, VueNative, NativeScript, etc.);`}</li>
      <li parentName="ul">{`PWA;`}</li>
      <li parentName="ul">{`Desktop apps (Electron, NW.js, etc.);`}</li>
      <li parentName="ul">{`Web API (Bluetooth, Network API, IndexedDB, Web Notifications, etc.);`}</li>
      <li parentName="ul">{`JS on devices, IoT;`}</li>
      <li parentName="ul">{`Security in JS;`}</li>
      <li parentName="ul">{`Tools' development with JS;`}</li>
      <li parentName="ul">{`JS and the ECMAScript specification;`}</li>
      <li parentName="ul">{`WebAssembly;`}</li>
      <li parentName="ul">{`Typescript, ClojureScript, Elm, Dart, etc;`}</li>
      <li parentName="ul">{`Application testing;`}</li>
      <li parentName="ul">{`Build tools;`}</li>
      <li parentName="ul">{`JS developer workflow`}</li>
    </ul>
    {
      /* <ExternalLinkButton
       label="CALL FOR SPEAKERS LINK IS HERE"
       url="https://bit.ly/call-for-papers-2024"
      /> */
    }
    <h1>{`Perks`}</h1>
    <p>{`If your proposal is selected, here is what you'll get:`}</p>
    <h2>{`Travel to the Venue`}</h2>
    <p>{`We will pay for your reasonable travel expenses to and from the conference.
We are happy to assist with booking your itinerary, but if you can book your own travel, we prefer to offer reimbursement.
Having people manage their own travel helps us remain focused on making the conference even better.`}</p>
    <h2>{`Travel from/to the airport`}</h2>
    <p>{`We fully cover transportation between the airport in Belgrade and your hotel.
We have a team of professional drivers that will be waiting for you as soon as you land.`}</p>
    <h2>{`Accommodations`}</h2>
    <p>{`We will provide you with a hotel room near the city center, starting the day before the conference.
This will allow you to get settled in, recharge, and participate in the Speakers dinner.
You are more than welcome to come earlier and/or stay later, but you will be responsible for those extra nights.`}</p>
    <p>{`If your employer can cover your travel and/or hotel costs, we will be happy to list them as a company sponsor.
The money we save will be reinvested into the general running of the conference.
This is valid for speakers coming from abroad.`}</p>
    <p>{`The venue and hotel are accessible for wheelchair users and people with reduced mobility.
If you have any questions or concerns about whether all your needs can be met, `}<a parentName="p" {...{
        "href": "mailto:contact@armada-js.com"
      }}>{`please contact us`}</a>{`.`}</p>
    <h2>{`Speaker's Dinner`}</h2>
    <p>{`One day before the conference we organize a dinner with all of our speakers and the organization team in a beautiful place with awesome view of the city.
This is a great place to network and meet new friends.`}</p>
    <p>{`Check out the pictures to see how our past speakers spent their time at our conference.`}</p>
    <GalleryDinnerSection mdxType="GalleryDinnerSection" />
    <h2>{`Tickets to the Conference`}</h2>
    <p>{`When you are not speaking, enjoy the conference!
You'll have full admission to the entire conference, social events, activities, and everything that takes place during the conference.
Please note that we may not be done with the speaker selections by the time tickets go on sale.
If you are selected to talk, let us know that you have purchased a ticket and we will provide a refund.`}</p>
    {
      /* <ExternalLinkButton
       label="CALL FOR SPEAKERS LINK IS HERE"
       url="https://bit.ly/call-for-papers-2024"
      /> */
    }
    <p>{`Maybe you're not interested in standing on stage but you know the perfect person that would give an interesting talk?
Please suggest a speaker!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      